





























import { Vue, Component, Prop } from "vue-property-decorator";
import { Locations, Auth } from "@/store/modules";
import { clone } from "ramda";

@Component
export default class Home extends Vue {
  listExpand = true;
  dropSites: any[] = [];
  vendors: any[] = [];
  selected = '';
  loading = false;
  query = '';

  get selectLocation () {
    return Locations.fetchOne({slug: this.selected})
  }

  get locations() {
    return Locations[this.locationsArrayName] || [];
  }

  get filteredLocations () {
    return this.locations.filter(location => location.name.toLowerCase().includes(this.query.toLowerCase()))
  }

  async beforeMount () {
    this.loading = true;
    await this.refresh();
    this.dropSites = clone(Locations.dropSites);
    this.vendors = clone(Locations.vendors);
    this.loading = false;
  }

  async refresh () {
    await Locations.fetchAll();
  }

  async selectPlace (id: string) {
    this.$router.push({ path: this.type === 'take' ? 'vendors' : 'return-stations', query: { id, showBottom: 'true' }});
  }

  get locationsArrayName () {
    return this.type === 'take' ? 'vendors' : 'dropSites';
  }

  get type() {
    return this.$route.query.type || 'take';
  }

  get take() {
    return this.type === 'take';
  }

  get return() {
    return this.type === 'return';
  }
}
